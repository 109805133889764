import Box from '@/buyers/components/Box'
import { useOrderContext } from '../context'
import ContactVendorCard from './ContactVendorCard'

const ContactVendor = () => {
  const { storeOrder } = useOrderContext()

  return (
    <Box className="text-center space-y-2 flex flex-col items-center" dim>
      <div className="text-lg font-medium">
        {storeOrder.quickOrder ? 'Check on price and availability' : 'Have Questions?'}{' '}
      </div>

      <div className="text-base">
        {storeOrder.quickOrder
          ? 'Call or message your vendor to confirm the price and availability.'
          : `Quickly reach out to ${storeOrder.store.name} by messaging them here, and they'll be notified immediately.`}
      </div>

      <div className="pt-2 w-full">
        <ContactVendorCard />
      </div>
    </Box>
  )
}

export default ContactVendor
