import { SelectedPaymentMethod, StoreOrderStep, useQuotedOrderQuery } from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import SidebarLayout from '@/buyers/components/SidebarLayout'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import EditPurchaseOrder from './EditPurchaseOrder'
import AdditionalCharges from './YourOrder/AdditionalCharges'
import ApproveForm from './YourOrder/ApproveForm'
import CloseRequest from './YourOrder/CloseRequest'
import LogCreditMemoModal from './YourOrder/CreditMemo/LogCreditMemoModal'
import DeliveryFees from './YourOrder/DeliveryFees'
import EditMachine from './YourOrder/EditMachine'
import EditShippingAddress from './YourOrder/EditShippingAddress'
import Invoices from './YourOrder/Invoices'
import MarkItemsReceived from './YourOrder/MarkItemsReceived'
import MarkProblemResolved from './YourOrder/MarkProblemResolved'
import Parts from './YourOrder/Parts'
import Prices from './YourOrder/Prices'
import Problems from './YourOrder/Problems'
import RejectModal from './YourOrder/RejectModal'
import ReportProblem from './YourOrder/ReportProblem'
import Shipment from './YourOrder/Shipment'
import StepNote from './YourOrder/StepNote'
import TutorialComplete from './YourOrder/TutorialComplete'
import ViewOriginalRequest from './YourOrder/ViewOriginalRequest'
import useOnApproved from './YourOrder/useOnApproved'
import useRedirectLegacyReviewPath from './YourOrder/useRedirectLegacyReviewPath'
import { useOrderContext } from './context'
import QuickOrderModal from './YourOrder/QuickOrder'
import ContactVendor from './YourOrder/ContactVendor'
import CancelQuickOrderModal from './YourOrder/CancelQuickOrderModal'

export const Section = ({ className, ...props }: JSX.IntrinsicElements['div']) => (
  <div className={classnames('pb-6 pt-6 first:pt-0 last:pb-0', className)} {...props} />
)

const YourOrder = () => {
  const { storeOrderId, storeOrder } = useOrderContext()
  const onApproved = useOnApproved()

  useRedirectLegacyReviewPath()

  const quotedOrderQuery = useQuotedOrderQuery({
    variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client: useGqlClient(),
    skip: storeOrder.step !== StoreOrderStep.Quoted,
  })

  const paymentOption = quotedOrderQuery.data?.storeOrder?.paymentOption

  const paymentMethods = (() => {
    if (!paymentOption) return undefined

    const result: SelectedPaymentMethod[] = []
    if (paymentOption.direct) result.push(SelectedPaymentMethod.Direct)
    if (paymentOption.balanceTerms) result.push(SelectedPaymentMethod.BalanceTerms)
    if (paymentOption.balance) result.push(SelectedPaymentMethod.Balance)
    if (paymentOption.stripe) result.push(SelectedPaymentMethod.Stripe)
    return result
  })()

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethod | null>()

  useEffect(() => {
    if (!selectedPaymentMethod && paymentMethods) setSelectedPaymentMethod(paymentMethods[0])
  }, [!paymentMethods])

  const selectedDirect =
    storeOrder.step === StoreOrderStep.Quoted &&
    selectedPaymentMethod === SelectedPaymentMethod.Direct

  const showPrices =
    !storeOrder.quickOrder || storeOrder.lineItems.some((i) => i.extendedPrice.amount > 0)

  return (
    <>
      <SidebarLayout sidebar={{ className: 'space-y-4 max-w-[21.8rem]' }}>
        <div className="space-y-4">
          <Parts canSelectParts={selectedDirect} />
        </div>

        <>
          <StepNote selectedDirect={selectedDirect} />
          <AdditionalCharges />

          {storeOrder.step === StoreOrderStep.Quoted ? (
            <ApproveForm
              paymentOption={paymentOption}
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              onApproved={onApproved}
            />
          ) : showPrices ? (
            <Box className="space-y-4">
              <Prices selectedDirect={selectedDirect} />
            </Box>
          ) : null}

          <DeliveryFees />
          <Problems />
          <Invoices />

          {!storeOrder.quickOrder && <ViewOriginalRequest />}

          <ContactVendor />
        </>
      </SidebarLayout>

      <Routes>
        <Route path="quick-order" element={<QuickOrderModal />} />
        <Route path="reject" element={<RejectModal />} />
        <Route path="edit-purchase-order" element={<EditPurchaseOrder />} />
        <Route path="tutorial-complete" element={<TutorialComplete />} />
        <Route path="report-problem" element={<ReportProblem />} />
        <Route path="mark-items-received" element={<MarkItemsReceived />} />
        <Route path="shipments/:shipmentId" element={<Shipment />} />
        <Route path="shipments/:shipmentId/report-problem" element={<ReportProblem />} />
        <Route path="problems/:problemId/mark-resolved" element={<MarkProblemResolved />} />
        <Route path="edit-machine" element={<EditMachine />} />
        <Route path="close-request" element={<CloseRequest />} />
        <Route path="edit-shipping-address" element={<EditShippingAddress />} />
        <Route
          path="log-credit-memo"
          element={
            <LogCreditMemoModal storeOrder={{ id: storeOrder.id, total: storeOrder.total }} />
          }
        />

        <Route path="cancel-quick-order" element={<CancelQuickOrderModal />} />
      </Routes>
    </>
  )
}

export default YourOrder
