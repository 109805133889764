import { useDealerLocationsQuery } from '@/buyers/_gen/gql'
import AddVendorModal from '@/buyers/components/AddVendorModal'
import BrandsFilter from '@/buyers/components/BrandsFilter'
import LocationSelector from '@/buyers/components/LocationSelector'
import { VendorBadgeType } from '@/buyers/components/Vendors/VendorBadge'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import RadioInput from '@/gf/components/next/forms/RadioInput'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import * as GE from '@/gf/modules/GrammarEvents'
import { Maybe, ModalSize, Point } from '@/types'
import { CheckIcon } from '@heroicons/react/outline'
import pluralize from 'pluralize'
import { useCallback, useMemo, useState } from 'react'
import VendorCard from './VendorCard'

type Filters = {
  location: Maybe<Point>
  search: string
  brands: string[]
  active: true | null
}

const createNearPoint = (location) => {
  if (location) {
    return { location, distance: null }
  }

  return null
}

const SearchLocationsModal = ({
  nearbyReferencePoint,
  locationId,
  machineBrand,
  onDealerLocationsChanged,
  onLocationChanged,
  excludeVendorIds,
  selectedIds,
  open,
  onClose,
  onVendorCreated,
  orgMachineId,
  singleSelection,
}: {
  selectedIds: string[]
  nearbyReferencePoint?: Point
  locationId: Maybe<string>
  machineBrand: Maybe<{ id: string; name: string }>
  onDealerLocationsChanged: (newValue: string[]) => void
  onLocationChanged: (point?: Point, locationId?: string) => void
  excludeVendorIds: string[]
  open: boolean
  onClose: () => void
  onVendorCreated: () => void
  orgMachineId: Maybe<string>
  singleSelection: boolean
}) => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const initialFilters = useMemo(
    () => ({
      location: nearbyReferencePoint ?? null,
      search: '',
      brands: machineBrand ? [machineBrand.id] : [],
      active: null,
    }),
    [nearbyReferencePoint, machineBrand]
  )
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const [addingVendor, addVendorToggle] = useToggle()
  const [_, msgs] = useMsgs()

  const updateFilters = (changes: Partial<Filters>) =>
    setFilters((prev) => ({ ...prev, ...changes }))

  const onSearchChanged = useCallback(
    (search) =>
      setFilters((prev) =>
        !prev.search && search
          ? {
              ...prev,
              active: null,
              brands: [],
              search,
            }
          : { ...prev, search }
      ),
    [initialFilters]
  )

  const { data, loading } = useDealerLocationsQuery({
    client,
    variables: {
      orgId,
      excludedVendorIds: excludeVendorIds,
      vendorIds: null,
      searchTerm: filters.search,
      nearPoint: createNearPoint(filters.location),
      brandIds: filters.brands,
      saved: false,
      drafts: null,
      preferredSettings: null,
      active: filters.active,
      tutorial: null,
    },
    skip: !open,
    fetchPolicy: 'network-only',
  })

  const _onClose = () => {
    addVendorToggle.off()
    onClose()
  }

  const isSelected = useCallback(
    (comparingDealerLocationId: string) =>
      !!selectedIds.find((dealerLocationId) => dealerLocationId === comparingDealerLocationId),
    [selectedIds]
  )

  const selectDealer = (selectedId: string) => {
    onDealerLocationsChanged([...selectedIds, selectedId])

    _onClose()
  }

  const removeDealer = (removedId: string) => {
    onDealerLocationsChanged(
      selectedIds.filter((dealerLocationId) => dealerLocationId !== removedId)
    )
    GE.whenCreateRequestPath(() => GE.removesDealerLocation(removedId))
  }

  const _onVendorCreated = (vendorId, name) => {
    selectDealer(vendorId)
    onVendorCreated()

    msgs.add(`Vendor ${name} was added to your account.`, 'positive')

    GE.whenCreateRequestPath(() => GE.createsVendor(vendorId))
  }

  const dealerLocations = data?.dealerLocations.entries ?? []
  const totalResults = data?.dealerLocations.pagination.totalResults

  if (!open) {
    return null
  }

  return (
    <Modal open={open} onClose={_onClose} size={ModalSize.LG}>
      {addingVendor ? (
        <AddVendorModal
          onClose={_onClose}
          orgMachineId={orgMachineId}
          onVendorCreated={_onVendorCreated}
          transparentFooter
          showCancelButton
          onCancel={addVendorToggle.off}
        />
      ) : (
        <>
          <div className="px-6 pt-6 space-y-4 ">
            <hgroup className="relative">
              <h4 className="text-2xl font-medium">Find Vendor</h4>
              <p className="text-lg">Browse all active vendors or add a new vendor to Gearflow.</p>

              <CloseModalButton onClick={_onClose} className="absolute -top-3 -right-3" />
            </hgroup>

            <div className="flex gap-x-4 xl:gap-x-6 w-full">
              <div className="w-full space-y-6">
                <div className="space-y-3">
                  <div className="flex gap-x-2">
                    <LocationSelector
                      value={locationId}
                      onChange={(location) => {
                        onLocationChanged(location.address.point ?? undefined, location.id)
                        updateFilters({ location: location.address.point })
                      }}
                    />

                    <input
                      type="text"
                      placeholder="Search for Vendor"
                      onChange={(e) => onSearchChanged(e.target.value)}
                      className="border border-gray-300 placeholder:text-gray-400 text-sm text-gray-900 focus:border-blue-600 focus:ring-blue-600 px-3 py-2 rounded-md w-86"
                    />
                  </div>

                  <div className="flex gap-x-6 items-center justify-between">
                    <div className="flex gap-x-2 items-center text-base">
                      <BrandsFilter
                        value={filters.brands}
                        onChange={(brands) => updateFilters({ brands })}
                      />
                      {filters.brands.length > 0 && (
                        <Action.T onClick={() => updateFilters({ brands: [] })}>
                          Clear makes
                        </Action.T>
                      )}
                    </div>

                    <div className="text-base flex gap-x-4">
                      <label className="flex items-center gap-x-2">
                        <RadioInput
                          name="location-type"
                          onChange={(e) =>
                            e.target.checked ? updateFilters({ active: true }) : undefined
                          }
                          checked={filters.active === true}
                        />
                        Active on Gearflow
                      </label>
                      <label className="flex items-center gap-x-2">
                        <RadioInput
                          name="location-type"
                          onChange={(e) =>
                            e.target.checked ? updateFilters({ active: null }) : undefined
                          }
                          checked={!filters.active}
                        />
                        All Vendors
                      </label>
                    </div>
                  </div>
                </div>

                <div className="relative">
                  <p className="text-sm text-gray-700 flex justify-between pr-36 py-2 border-b border-gray-300">
                    <span>
                      {totalResults} {pluralize('result', totalResults ?? 1)}
                    </span>
                    <span>Sorting by distance</span>
                  </p>
                  <div className="h-[55vh] overflow-y-auto fancy-scroll pr-2">
                    {loading ? (
                      [1, 2, 3].map((i) => <VendorCard.Loading key={i} className="py-6" />)
                    ) : dealerLocations.length === 0 ? (
                      <p className="py-4">Sorry, no vendors were found for your filters.</p>
                    ) : (
                      dealerLocations.map((dl, i) => (
                        <div
                          key={dl.id}
                          className="flex items-start gap-x-4 py-6 border-b last:border-0 border-gray-200"
                        >
                          <VendorCard
                            name={dl.name}
                            distance={dl.distance}
                            logoUrl={dl.logoUrl}
                            numberOfQuotes={dl.numOfQuotes}
                            responseTimeSec={dl.timeToQuoteSec}
                            badges={dl.vendor?.draft ? [VendorBadgeType.DraftVendor] : []}
                            address={{
                              city: dl.address?.city ?? dl.cityState,
                              state: dl.address?.city ? dl.address?.state : null,
                            }}
                            actionButton={
                              isSelected(dl.id) ? (
                                <Action.S
                                  className="flex-shrink-0 w-9 h-9"
                                  onClick={() => removeDealer(dl.id)}
                                >
                                  <div className="flex flex-row justify-center items-center gap-x-1">
                                    <CheckIcon className="flex flex-shrink-0 w-5 h-5" />
                                  </div>
                                </Action.S>
                              ) : (
                                <Action.P
                                  className="flex-shrink-0 w-32"
                                  color="blue"
                                  onClick={() => {
                                    selectDealer(dl.id)

                                    GE.whenCreateRequestPath(() =>
                                      GE.selectsDealerLocation(dl.id, i, filters, !dl.vendor)
                                    )
                                  }}
                                  disabled={singleSelection && selectedIds.length > 0}
                                >
                                  Select
                                </Action.P>
                              )
                            }
                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t p-4">
            <p className="text-center flex items-center justify-center gap-x-4">
              Not seeing your vendor?{' '}
              <Action.S onClick={addVendorToggle.on}>Add New Vendor</Action.S>
            </p>
          </div>
        </>
      )}
    </Modal>
  )
}

export default SearchLocationsModal
