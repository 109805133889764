import { GraphQLError } from 'graphql'

import { useDeleteVendorContactMutation, VendorContact } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import ModalForm from '@/gf/components/ModalForm'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { useContext } from '../../Vendor'

const DeleteContactModal = ({
  vendorContact,
  open,
  onClose,
  onDelete,
}: {
  vendorContact: VendorContact
  open: boolean
  onClose: () => void
  onDelete?: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [deleteVendorContact] = useDeleteVendorContactMutation({ client: useGqlClient() })
  const { vendor } = useContext()

  const onSubmit = () => {
    spinner.on()
    deleteVendorContact({
      variables: { contactId: vendorContact.id, vendorId: vendor.id },
      refetchQueries: ['VendorContacts'],
    })
      .then(() => {
        msgr.add('Vendor contact deleted.', 'positive')
        onDelete?.()
      })
      .catch((err: GraphQLError) => {
        if (err.message) msgr.add(err.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(() => {
        spinner.off()
        onClose()
      })
  }

  return (
    <ModalForm
      title="Delete Vendor Contact"
      open={open}
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
      submitButtonText="Yes"
      submitButtonShowSpinner={spinnerLive}
      submitButtonDisabled={spinnerLive}
    >
      <div className="space-y-2">
        <p className="prose">
          Are you sure you want to delete <span className="font-bold">{vendorContact?.name}</span>?
          This will remove the contact from your account, but the user will remain active in
          Gearflow
        </p>
      </div>
    </ModalForm>
  )
}

export default DeleteContactModal
