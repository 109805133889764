import {
  SearchLocationBillingAddressesDocument,
  ShippingLocationsSearchQuery,
  useFetchOrganizationUsersQuery,
  useOrganizationDetailsQuery,
  useSearchLocationBillingAddressesQuery,
  useSetLocationBillingAddressMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Address from '@/gf/components/Address'
import Ghost from '@/gf/components/Ghost'
import LabeledText from '@/gf/components/LabeledText'
import SidebarProfileView from '@/gf/components/Layout/SidebarProfileView'
import Link from '@/gf/components/Link'
import RedAlert from '@/gf/components/RedAlert'
import SelectUsersModal from '@/gf/components/SelectUsersModal'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import AddressM from '@/gf/modules/Address'
import { ApolloError, gql, useMutation } from '@apollo/client'
import { PencilIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import AddressInput from '@/gf/components/AddressInput'
import ModalForm from '@/gf/components/ModalForm'
import Combobox from '@/gf/components/next/Combobox'

const ASSIGN_SHIPPING_LOCATION = gql`
  mutation AssignShippingLocationUsers($userIds: [String!]!, $shippingLocationId: String!) {
    assignShippingLocationUsers(userIds: $userIds, shippingLocationId: $shippingLocationId) {
      id
    }
  }
`

interface SidebarLocationViewProps {
  shippingLocation: ShippingLocationsSearchQuery['shippingLocationsSearch']['shippingLocations'][number]
  onEditClick: () => void
  onDataChanged: () => void
}

const SidebarLocationView = ({
  shippingLocation,
  onEditClick,
  onDataChanged,
}: SidebarLocationViewProps) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [assignShippingLocationMutation] = useMutation(ASSIGN_SHIPPING_LOCATION)
  const gqlClient = useGqlClient()
  const {
    data: organizationUsers,
    loading: organizationUsersLoading,
    error: organizationUsersError,
  } = useFetchOrganizationUsersQuery({ client: gqlClient })
  const {
    organization: { id: organizationId },
  } = useSession()
  const { data: organizationData, error: organizationError } = useOrganizationDetailsQuery({
    variables: { id: organizationId },
    client: gqlClient,
  })
  const orgBillingAddress = organizationData?.org?.billingAddress
  const { data: billingAddressesData, error: billingAddressesError } =
    useSearchLocationBillingAddressesQuery({
      variables: { organizationId: organizationId, search: '' },
      client: gqlClient,
    })
  const [setLocationBillingAddress] = useSetLocationBillingAddressMutation({
    client: gqlClient,
    refetchQueries: [SearchLocationBillingAddressesDocument],
  })
  const [assignModalOpen, assignModalToggler] = useToggle()
  const [spinnerLive, spinnerLiveToggle] = useToggle()

  const [billingAddressModalOpen, setBillingAddressModalOpen] = useState(false)
  const [addressPerforming, setAddressPerforming] = useState(false)
  const [addressError, setAddressError] = useState<string>()
  const [address, setAddress] = useState<Address>(AddressM.init())
  const [comboOpen, setComboOpen] = useState(false)
  const [selectedBillingAddressId, setSelectedBillingAddressId] = useState<string | null>()
  const [newAddress, setNewAddress] = useState(false)

  useEffect(() => {
    if (organizationData?.org?.name)
      setAddress({ ...address, companyName: organizationData.org.name })
  }, [organizationData?.org?.name])

  const assignUsers = async (userIds) => {
    spinnerLiveToggle.on()

    try {
      await assignShippingLocationMutation({
        variables: { userIds, shippingLocationId: shippingLocation.id },
      })

      onDataChanged()
    } catch (err) {
      const message =
        err instanceof ApolloError
          ? err.message
          : 'Whoops, something went wrong. Please contact support.'
      msgsMgr.add(message, 'negative')
    } finally {
      spinnerLiveToggle.off()
      assignModalToggler.off()
    }
  }

  const onClearAddressForm = () => {
    setNewAddress(false)
    setSelectedBillingAddressId(undefined)
    setComboOpen(false)
    setAddress({ ...AddressM.init(), companyName: organizationData?.org?.name ?? '' })
    setAddressError(undefined)
  }

  return (
    <SidebarProfileView
      title={shippingLocation.name}
      detail={shippingLocation.code ? `Code: ${shippingLocation.code}` : null}
      showImagePlaceholder={false}
      onEditClick={onEditClick}
      className="w-full md:w-64"
    >
      <SelectUsersModal
        titleText="Assign users to this location"
        detailsText="Select the people who should receive notifications"
        open={assignModalOpen}
        onClose={assignModalToggler.off}
        onSubmit={assignUsers}
        spinnerLive={spinnerLive}
        users={organizationUsers?.fetchOrganizationUsers}
        usersLoading={organizationUsersLoading}
        usersError={!!organizationUsersError}
        initialSelectedUsers={shippingLocation.shippingLocationUsers}
      />

      <ModalForm
        title="Billing Address"
        open={billingAddressModalOpen}
        submitButtonShowSpinner={addressPerforming}
        onClose={() => {
          setBillingAddressModalOpen(false)
          onClearAddressForm()
        }}
        onSubmit={(e) => {
          e.preventDefault()
          setAddressPerforming(true)
          setLocationBillingAddress({
            variables: {
              locationId: shippingLocation.id,
              billingAddress: newAddress
                ? {
                    lineOne: address.lineOne,
                    lineTwo: address.lineTwo,
                    city: address.city,
                    state: address.state,
                    postalCode: address.postalCode,
                    country: address.country,
                    companyName: address.companyName,
                    firstName: address.firstName,
                    lastName: address.lastName,
                    deliverable: null,
                    point: null,
                    rdi: null,
                  }
                : null,
              billingAddressId: selectedBillingAddressId ?? null,
            },
          })
            .then(onDataChanged)
            .then(() => {
              setBillingAddressModalOpen(false)
              onClearAddressForm()
            })
            .catch(() => setAddressError('Error, please contact support'))
            .finally(() => setAddressPerforming(false))
        }}
        submitButtonText="Save"
        submitButtonDisabled={
          addressPerforming ||
          !(!!selectedBillingAddressId || selectedBillingAddressId === null || newAddress)
        }
      >
        <div className="mt-6 mb-2 space-y-4">
          {newAddress ? (
            <AddressInput address={address} onChange={setAddress} hideFirstLastName />
          ) : (
            <div className="space-y-1">
              <p className="text-sm text-gray-500">Select a billing address</p>
              {billingAddressesError ? (
                <RedAlert title="Error, please contact support" />
              ) : billingAddressesData ? (
                <Combobox
                  open={comboOpen}
                  onBlur={() => setComboOpen(false)}
                  onFocus={() => setComboOpen(true)}
                  value={
                    selectedBillingAddressId === null
                      ? 'remove'
                      : billingAddressesData.searchLocationBillingAddresses.find(
                          ({ id }) => id === selectedBillingAddressId
                        )
                  }
                  options={[
                    'new',
                    ...(shippingLocation.billingAddress ? ['remove'] : []),
                    ...(billingAddressesData?.searchLocationBillingAddresses ?? []),
                  ]}
                  onChange={(option) => {
                    if (typeof option === 'string') {
                      if (option === 'new') {
                        setNewAddress(true)
                        setSelectedBillingAddressId(undefined)
                      }
                      if (option === 'remove') {
                        setSelectedBillingAddressId(null)
                      }
                    } else if (!option) {
                      setSelectedBillingAddressId(null)
                    } else if ('id' in option) {
                      setSelectedBillingAddressId(option.id)
                    }
                  }}
                  getOptionDisplay={(option) =>
                    option === 'new'
                      ? '[ + New billing address ]'
                      : option === 'remove'
                        ? '[ - Remove billing address ]'
                        : typeof option === 'object'
                          ? AddressM.formatOneLineNoCountry(option)
                          : ''
                  }
                />
              ) : (
                <Ghost className="w-full block h-12" />
              )}
            </div>
          )}
          {addressError && <RedAlert title={addressError} />}
        </div>
      </ModalForm>

      {shippingLocation.address && (
        <LabeledText
          label="Address"
          value={
            <Address
              className="text-slate-700 font-semibold text-sm"
              address={shippingLocation.address}
            />
          }
        />
      )}

      {shippingLocation.phoneNumber && (
        <LabeledText label="Phone" value={Phone.format(shippingLocation.phoneNumber)} />
      )}

      <LabeledText label="Use as Default" value={shippingLocation.defaultLocation ? 'Yes' : 'No'} />

      <LabeledText
        label="Notifications"
        labelAction={
          <Action.T onClick={assignModalToggler.on} className="p-2 rounded-full hover:bg-slate-50">
            <PencilIcon className="h-5 text-zinc-400" />
          </Action.T>
        }
        value={
          <div>
            {shippingLocation.shippingLocationUsers.length === 0 ? (
              <p className="text-slate-500 italic font-normal text-medium">No users assigned</p>
            ) : (
              <p>{shippingLocation.shippingLocationUsers.map((u) => u.displayName).join(', ')}</p>
            )}
          </div>
        }
      />

      {/* Hide the billing address for "Branches" right now */}
      {!shippingLocation.branch && (
        <LabeledText
          label="Billing Address"
          labelAction={
            // Disable the edit action for branches
            !shippingLocation.branch ? (
              <Action.T
                onClick={() => {
                  setBillingAddressModalOpen(true)
                  setSelectedBillingAddressId(shippingLocation.billingAddress?.id)
                }}
                className="p-2 rounded-full hover:bg-slate-50"
              >
                <PencilIcon className="h-5 text-zinc-400" />
              </Action.T>
            ) : undefined
          }
          value={
            <div className="w-full text-slate-700 text-sm">
              {shippingLocation.billingAddress ? (
                <div>
                  <Address address={shippingLocation.billingAddress} />
                </div>
              ) : organizationError ? (
                <RedAlert title="Error, please contact support" />
              ) : !organizationData ? (
                <Ghost className="w-full h-12 block" />
              ) : orgBillingAddress ? (
                <div>
                  <span className="italic">(Your default billing address)</span>
                  {orgBillingAddress && <Address className="mt-1" address={orgBillingAddress} />}
                </div>
              ) : (
                <div>
                  <p className="italic">(Same as shipping address)</p>
                  <p className="mt-1">
                    Configure a default billing address across all your locations{' '}
                    <Link.T to="/settings/billing">here</Link.T>
                  </p>
                </div>
              )}
            </div>
          }
        />
      )}
    </SidebarProfileView>
  )
}

export default SidebarLocationView
