import { useNavigate } from 'react-router-dom'
import { CreateRequestSelectLocationsDocument, useAllBranchesQuery } from '@/buyers/_gen/gql'
import LocationModal from '@/buyers/components/LocationModal'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import useToggle from '@/gf/hooks/useToggle'
import AddressM from '@/gf/modules/Address'
import { Point } from '@/types'
import LocationsList from './SelectLocationStep/LocationsList'
import StartOverButton from './StartOverButton'
import StickyBottomBar from './StickyBottomBar'
import { PartialRequestUpdate } from './types'

const SelectLocationStep = ({
  reset,
  request,
  updateRequest,
  onBackClicked,
  onLocationChanged,
  orgHasLocations = true,
}: {
  reset?: () => void
  onBackClicked: () => void
  onLocationChanged: (point: Point, selectedLocationId?: string) => void
  orgHasLocations?: boolean
} & PartialRequestUpdate) => {
  const navigate = useNavigate()
  const client = useGqlClient()
  const { organization } = useSession()
  const [locationModalOpen, locationModalToggle] = useToggle()
  const branches = useAllBranchesQuery({ variables: { value: '' }, client }).data?.allBranches

  const onNext = () => {
    // TODO: this is only if the order type selection is available
    navigate('../order-type')
  }

  return (
    <>
      <LocationModal
        open={locationModalOpen}
        refetchQueries={[CreateRequestSelectLocationsDocument]}
        onClose={locationModalToggle.off}
        onComplete={(locationId, variables) =>
          onLocationChanged(variables.address?.point as Point, locationId)
        }
        branches={(organization.requireBillingCompany ? branches : []) ?? []}
        buyers={[]}
        initialAddress={AddressM.init()}
        showPersist
      />

      <Box className="shadow-base rounded-xl px-6 py-8">
        <div className="space-y-6 max-w-screen-md mx-auto">
          <h4 className="text-xl font-medium text-gray-900">
            What location do you need the parts for?
          </h4>

          <LocationsList
            onLocationSelected={(point, locationId) =>
              updateRequest({
                nearbyReferencePoint: point,
                locationId,
              })
            }
            selectedLocationId={request.locationId ?? null}
            onAddLocationClicked={locationModalToggle.on}
            orgHasLocations={orgHasLocations}
          />
        </div>
      </Box>

      <StickyBottomBar>
        {reset && <StartOverButton reset={reset} />}

        <div className="flex-grow" />

        {onBackClicked && <Action.S onClick={onBackClicked}>Previous</Action.S>}

        <Action.P color="blue" onClick={() => onNext()} disabled={!request.locationId}>
          Save and Select Vendors
        </Action.P>
      </StickyBottomBar>
    </>
  )
}

export default SelectLocationStep
