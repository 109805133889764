import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import { ModalSize } from '@/types'
import { useState } from 'react'

const PONumberModal = ({
  open,
  onClose,
  onPoNumberConfirmed,
}: {
  open: boolean
  onClose: () => void
  onPoNumberConfirmed: (poNumber: string) => void
}) => {
  const [poNumber, setPoNumber] = useState<string>('')

  const onConfirm = () => {
    onPoNumberConfirmed(poNumber)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <div className="px-6 py-4 relative space-y-6">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
        <hgroup className="space-y-2">
          <h2 className="text-2xl font-medium">Add a PO Number Before Sending</h2>
          <p className="text-lg">
            Reduce errors and ensure accurate tracking by including a purchase order number to this
            order.
          </p>
        </hgroup>

        <Field label="PO Number">
          <TextInput value={poNumber} onChange={(e) => setPoNumber(e.target.value)} required />
        </Field>

        <Action.P disabled={!poNumber} onClick={onConfirm} className="w-full">
          Confirm and Send Order
        </Action.P>
      </div>
    </Modal>
  )
}

export default PONumberModal
