import { omit } from 'lodash'
import nth from 'lodash/nth'
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import {
  useBrokerCopyMachineMutation,
  useBrokerCopyShippingLocationMutation,
  useBrokerRequestQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import usePersistedState from '@/buyers/pages/CreateRequest/usePersistedState'

import RequestForQuoteM from '../modules/RequestForQuote'

import Spinner from '@/gf/components/Spinner'

const BrokerRequest = () => {
  const { rfqId } = useParams() as { rfqId: string }
  const { update: updateCreateRequestForm } = usePersistedState('new-request-data-2')
  const [copied, setCopied] = useState(false)
  const gqlClient = useGqlClient()
  const [brokerCopyMachine] = useBrokerCopyMachineMutation({ client: gqlClient })
  const [brokerCopyShippingLocation] = useBrokerCopyShippingLocationMutation({ client: gqlClient })

  const filter = JSON.stringify(['id_eq', rfqId])

  const rfq = useBrokerRequestQuery({
    variables: { filter },
    client: gqlClient,
  }).data?.rfqs[0]

  useEffect(() => {
    if (rfq) {
      const requestMachineOrgId = nth(rfq.orgMachines, 0)?.id
      const requestLocationId = rfq.shippingAddress?.location?.id
      Promise.all([
        // Potentially create a new machine
        (async () => {
          if (requestMachineOrgId) {
            try {
              const result = await brokerCopyMachine({
                variables: { orgMachineId: requestMachineOrgId },
              })
              return result.data?.brokerCopyOrgMachine.id
            } catch {
              // Regular message gets wiped with the page navigation, so use an alert instead
              window.alert('Error copying machine. Please add the machine yourself.')
              return undefined
            }
          }
          return undefined
        })(),
        // Potentially create a new location?
        (async () => {
          if (requestLocationId) {
            try {
              const result = await brokerCopyShippingLocation({
                variables: { locationId: requestLocationId },
              })
              return result.data
                ? {
                    locationId: result.data.brokerCopyShippingLocation.id,
                    nearbyReferencePoint: omit(
                      result.data.brokerCopyShippingLocation.address.point,
                      ['__typename']
                    ),
                  }
                : undefined
            } catch {
              // Regular message gets wiped with the page navigation, so use an alert instead
              window.alert(
                'Error copying shipping location. Please add the shipping location yourself.'
              )
              return undefined
            }
          }
          return undefined
        })(),
      ]).then(([machineOrgId, location]) => {
        updateCreateRequestForm({
          workOrderNumber: undefined,
          machineOrgId,
          // make sure machineInvolved is true if there was an error copying the machine
          machineInvolved: !!requestMachineOrgId || !!machineOrgId,
          urgency: rfq.neededBy
            ? {
                machineDown: rfq.machineDown,
                neededByDate: rfq.neededBy,
                priority: RequestForQuoteM.apiPriorityToCreateRfqPriority(rfq.priority),
              }
            : undefined,
          parts: rfq.parts.map((pr, index) => ({
            partNumber: pr.mpn,
            description: pr.description,
            quantity: pr.quantity,
            externalId: pr.externalId ?? null,
            taskNumber: pr.taskNumber ?? null,
            suggestion: pr.suggestion ?? null,
            // Put all the pictures on the first part until we have images linked to parts in the Request
            pictures: index === 0 ? rfq.images.map(({ url }) => url) : [],
          })),
          comments: rfq.partsRequest ?? '',
          quickOrder: false,
          broadcastToNetwork: false,
          locationId: location?.locationId,
          nearbyReferencePoint: location?.nearbyReferencePoint,
          brokerRfqId: rfq.id,
        })

        setCopied(true)
      })
    }
  }, [!rfq])

  return copied ? (
    <Navigate to="/rfqs/create" replace />
  ) : (
    <div className="p-16 flex justify-center items-center">
      <Spinner />
    </div>
  )
}

export default BrokerRequest
