import { useState } from 'react'
import { useCancelRfqMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import { ModalSize } from '@/types'
import { useOrderContext } from '../context'
import CloseModalButton from '@/gf/components/CloseModalButton'
import { useNavigate } from 'react-router-dom'
import Field from '@/gf/components/next/forms/Field'
import TextArea from '@/gf/components/next/forms/TextArea'

const CancelQuickOrderModal = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const [additionalDetails, setAddtionalDetails] = useState<string>('')
  const [_msgs, msgsMgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [cancelRequest] = useCancelRfqMutation({ client: useGqlClient() })
  const navigate = useNavigate()

  const onSubmit = async () => {
    spinner.on()

    try {
      await cancelRequest({
        variables: {
          requestForQuoteId: storeOrder.order.requestForQuote?.id as string,
          reason: additionalDetails,
        },
      })

      msgsMgr.add('Order canceled!', 'positive')

      navigate(`/orders/${storeOrder.id}`, { replace: true })

      refetchStoreOrder()
    } catch (err) {
      console.log(err)
      msgsMgr.addUnknownError()
    } finally {
      spinner.off()
    }
  }

  return (
    <Modal open onClose={() => undefined} size={ModalSize.SM}>
      <div className="relative p-6">
        <CloseModalButton
          onClick={() => navigate(`/orders/${storeOrder.id}`, { replace: true })}
          className="absolute top-3 right-3"
        />

        <div className="space-y-4">
          <hgroup className="space-y-1">
            <h3 className="font-medium text-2xl">Cancel Purchase Order</h3>
            <p className="text-base">
              Once you submit we'll let the vendor know the order is canceled.
            </p>
          </hgroup>

          <Field label="What is the reason for canceling this order?">
            <TextArea
              value={additionalDetails}
              onChange={(e) => setAddtionalDetails(e?.target.value)}
            />
          </Field>

          <div className="flex justify-end">
            <Action.P onClick={onSubmit} disabled={!additionalDetails} performing={spinnerLive}>
              Cancel Order
            </Action.P>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CancelQuickOrderModal
