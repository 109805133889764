import { Navigate, Route, Routes } from 'react-router-dom'
import { Point } from '@/types'
import { PartialRequestUpdate, VendorSelectionType } from './types'
import MyVendors from './SelectVendorsStep/MyVendors'

const SelectVendorsStep = ({
  reset,
  request,
  updateRequest,
  onSubmit,
  onBackClicked,
  vendorSelectionType,
  gearflowNetworkEnabled = true,
}: {
  reset?: () => void
  onSubmit: () => void
  onBackClicked: () => void
  vendorSelectionType: VendorSelectionType
  gearflowNetworkEnabled?: boolean
} & PartialRequestUpdate) => {
  return (
    <div>
      <Routes>
        <Route path="" element={<Navigate to="select" replace />} />

        <Route
          path="*"
          element={
            <div className="space-y-4">
              <MyVendors
                shippingAddress={request.shippingAddress}
                dealerLocationIds={request.dealerLocationIds}
                locationId={request.locationId ?? null}
                machineOrgId={request.machineOrgId ?? null}
                nearbyReferencePoint={request.nearbyReferencePoint as Point}
                vendors={request.vendors}
                reset={reset}
                onSubmit={onSubmit}
                onBackClicked={() => onBackClicked()}
                vendorSelectionType={vendorSelectionType}
                onDealerLocationsChanged={(selectedDealerLocationIds) =>
                  updateRequest({ dealerLocationIds: selectedDealerLocationIds })
                }
                onLocationChanged={(point, selectedLocationId) =>
                  updateRequest({ nearbyReferencePoint: point, locationId: selectedLocationId })
                }
                onContactAndDeliveryChanged={(values) => updateRequest(values)}
                tutorial={request.tutorial}
                singleSelection={request.quickOrder}
                broadcast={
                  gearflowNetworkEnabled && !request.quickOrder
                    ? request.broadcastToNetwork
                    : undefined
                }
                onBroadcastChanged={(broadcastToNetwork) => updateRequest({ broadcastToNetwork })}
              />
            </div>
          }
        />
      </Routes>
    </div>
  )
}

export default SelectVendorsStep
