import { useState, useEffect } from 'react'
import { useRecommendPartsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Box from '@/gf/components/Box'
import Tooltip from '@/gf/components/Tooltip'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const SuggestedPart = ({ addPart, partNumber, description }) => (
  <div className="flex w-full gap-x-3">
    <div className="flex-grow flex gap-x-4 min-w-0">
      <div className="flex-grow min-w-0 capitalize">
        <Tooltip text={description}>
          <div className="flex items-center">
            <h3 className="text-md font-medium leading-6 text-gray-900 truncate overflow-ellipsis">
              {description}
            </h3>
          </div>
        </Tooltip>
        <div className="flex items-center">
          <span className="text-gray-500 leading-5 text-sm flex-shrink-0">{partNumber}</span>
        </div>
        <div className="flex justify-end">
          <div className="pt-2 self-end">
            <button
              onClick={() => addPart(partNumber, description)}
              type="button"
              className="inline-block border font-normal rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 text-white border-transparent bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 px-4 py-2 text-sm flex-shrink-0 w-[4.5rem] h-9"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

const SuggestedParts = ({
  make,
  addPart,
  partNumbers,
  orderedWithText,
}: {
  make: string
  addPart: (partNumber: string, desc: string) => void
  partNumbers: string[]
  orderedWithText?: string
}) => {
  const client = useGqlClient()
  const partNumbersSearch = partNumbers.reduce((acc, s) => acc + (acc ? ' ' : '') + s, '')
  const debouncedSearch = useDebounce(partNumbersSearch, 600)

  const { data: recommendParts } = useRecommendPartsQuery({
    client,
    variables: { parts: debouncedSearch, make },
    skip: partNumbers.length === 0 || !make || !debouncedSearch,
  })

  const suggestedParts = recommendParts?.recommendParts || []

  if (suggestedParts.length === 0) {
    return null
  }

  return (
    <div className="p-4 mb-6 bg-gray-50 space-y-4 rounded-xl">
      <h4 className="text-base font-medium text-gray-600 flex items-center justify-between">
        <span className="truncate text-ellipsis overflow-hidden max-w-prose">
          Frequently ordered with {orderedWithText}
        </span>
        <div className="flex gap-x-2">
          <button type="button" className="arrow-left">
            <ChevronLeftIcon className="inline-block w-5 h-5" />
          </button>
          <button type="button" className="arrow-right">
            <ChevronRightIcon className="inline-block w-5 h-5" />
          </button>
        </div>
      </h4>

      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={15}
        slidesPerView={1}
        navigation={{
          enabled: true,
          nextEl: '.arrow-right',
          disabledClass: 'text-gray-400',
          prevEl: '.arrow-left',
        }}
        breakpoints={{
          '768': {
            slidesPerView: 2,
          },
          '1280': {
            slidesPerView: 3,
          },
          '1536': {
            slidesPerView: 4,
          },
        }}
      >
        {suggestedParts.map((p) => (
          <SwiperSlide key={p?.id}>
            <Box key={p?.id} className="shadow-base p-4">
              <SuggestedPart
                addPart={addPart}
                partNumber={p?.partNumber}
                description={p?.description}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SuggestedParts
