import { ManualDetailsQuery } from '@/buyers/_gen/gql'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import { ModalSize } from '@/types'

const ViewComponentModal = ({
  open,
  onClose,
  component,
  highlighedPart,
}: {
  open: boolean
  onClose: () => void
  component: ManualDetailsQuery['manual']['components'][number]
  highlighedPart: string | null
}) => (
  <Modal open={open} onClose={onClose} size={ModalSize.LG}>
    <div className="relative">
      <CloseModalButton className="absolute top-3 right-3" onClick={onClose} />
      <div className="p-6 space-y-6">
        <h3 className="text-2xl font-medium">
          {component.number} {component.name}{' '}
          {component.serialNumber && `(${component.serialNumber})`}
        </h3>

        <Table>
          <Thead>
            <Tr>
              <Th>Ref Number</Th>
              <Th>Part Number</Th>
              <Th>Part Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            {component.parts.map((p) => (
              <Tr key={p.id} className={highlighedPart === p.id ? 'bg-yellow-300' : undefined}>
                <Td>{p.graphicsRefNumber}</Td>
                <Td>{p.number}</Td>
                <Td>{p.name}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {component.graphics.map((g) => (
          <div key={g}>
            <img src={g} alt="" />
          </div>
        ))}
      </div>
    </div>
  </Modal>
)

export default ViewComponentModal
