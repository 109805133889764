import { ApolloError } from '@apollo/client'
import { useState } from 'react'

import { useExtractPartsFromCsvMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useUppy from '@/gf/hooks/useUppy'

import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import UppyFileInput from '@/gf/components/UppyFileInput'
import YellowAlert from '@/gf/components/YellowAlert'

import { Part } from '../PartDetailsStep'

const allowedFileTypes = ['text/csv']

const UploadPartsFileButton = ({
  onFileUploaded,
  disabled,
}: {
  onFileUploaded: (parts: Part[]) => void
  disabled: boolean
}) => {
  const [, msgr] = useMsgs()
  const [open, toggle] = useToggle()
  const [loading, loadingToggle] = useToggle()
  const [parts, setParts] = useState<Part[]>([])
  const [extractPartsFromCsv] = useExtractPartsFromCsvMutation({ client: useGqlClient() })

  const uppy = useUppy({
    allowedFileTypes,
    autoProceed: true,
    onFilesAdded: () => loadingToggle.on(),
    onComplete: (uploadedFiles) => {
      loadingToggle.off()

      extractPartsFromCsv({ variables: { url: uploadedFiles[0].url } })
        .then(({ data }) => {
          setParts(
            data?.extractPartsFromCsv?.map((part) => ({
              ...part,
              probability: null,
              externalId: null,
              taskNumber: null,
              suggestion: null,
              suggestedParts: [],
            })) || []
          )
        })
        .catch((err: ApolloError) => {
          const errors = err.graphQLErrors.map((e) => e.message)

          if (errors.length > 0) errors.map((e) => msgr.add(e, 'negative'))
          else msgr.addUnknownError()
        })
        .finally(() => loadingToggle.off())
    },
  })

  return (
    <>
      <Modal open={open} onClose={toggle.off}>
        <div className="p-6 space-y-4 relative text-base">
          <h2 className="flex justify-between text-xl items-center">
            Upload Parts{' '}
            <CloseModalButton onClick={toggle.off} className="absolute top-3 right-3" />
          </h2>

          <div className="text-gray-600">
            <p>Upload a .csv file with the following columns:</p>

            <ul className="list-disc list-inside">
              <li>Quantity</li>

              <li>
                MPN (optional when <b className="font-medium">description</b> is present)
              </li>

              <li>
                Description (optional when <b className="font-medium">mpn</b> is present)
              </li>
            </ul>

            <p className="font-medium text-gray-900">MPN and Description cannot both be empty</p>

            <UppyFileInput
              uppy={uppy}
              allowedFileTypes={allowedFileTypes}
              required
              className="mt-4 w-full"
              onFileInputError={(error) => msgr.add(error, 'negative')}
            />
          </div>

          {parts.length > 0 && (
            <YellowAlert title="Are you sure?">
              The parts in the form will be replaced by the uploaded parts.
            </YellowAlert>
          )}

          <div className="flex justify-end">
            <Action.P
              onClick={() => onFileUploaded(parts)}
              performing={loading}
              disabled={parts.length === 0}
            >
              Add Uploaded Parts
            </Action.P>
          </div>
        </div>
      </Modal>

      <Action.T
        disabled={disabled}
        className="flex-shrink-0 font-medium leading-6 "
        onClick={toggle.on}
      >
        Upload Parts
      </Action.T>
    </>
  )
}

export default UploadPartsFileButton
