import { useSelectedMachineQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import { Maybe, Point } from '@/types'
import { Route, Routes, useNavigate } from 'react-router-dom'
import StartOverButton from '../StartOverButton'
import StickyBottomBar from '../StickyBottomBar'
import { CreateRequestState, VendorSelectionType } from '../types'
import ContactAndDeliveryInformation, {
  ContactAndDeliveryInformationSubmit,
} from './MyVendors/ContactAndDeliveryInformation'
import VendorsSelector from './MyVendors/VendorsSelector'

const MyVendors = ({
  shippingAddress,
  dealerLocationIds,
  locationId,
  nearbyReferencePoint,
  vendors,
  machineOrgId,
  onDealerLocationsChanged,
  onLocationChanged,
  onContactAndDeliveryChanged,
  reset,
  onBackClicked,
  onSubmit,
  vendorSelectionType,
  locationSelectorEnabled = true,
  broadcast,
  onBroadcastChanged,
  excludeVendorIds = [],
  disabledContactIds = [],
  tutorial = false,
  singleSelection = false,
}: {
  shippingAddress: CreateRequestState['shippingAddress']
  dealerLocationIds: string[]
  locationId: Maybe<string>
  nearbyReferencePoint: Point
  vendors: CreateRequestState['vendors']
  machineOrgId: Maybe<string>
  onDealerLocationsChanged: (dealerLocations: string[]) => void
  onLocationChanged: (point?: Point, selectedLocationId?: string) => void
  onContactAndDeliveryChanged: (values: ContactAndDeliveryInformationSubmit) => void
  reset?: () => void
  onBackClicked?: () => void
  onSubmit: () => void
  vendorSelectionType: VendorSelectionType
  locationSelectorEnabled?: boolean
  broadcast?: boolean
  onBroadcastChanged?: (broadcast: boolean) => void
  excludeVendorIds?: string[]
  disabledContactIds?: string[]
  tutorial?: boolean
  singleSelection?: boolean
}) => {
  const client = useGqlClient()
  const navigate = useNavigate()

  const { data: machineData } = useSelectedMachineQuery({
    client,
    variables: { id: machineOrgId as string },
    skip: !machineOrgId,
  })

  const machineBrand = machineData?.orgMachine?.machine.brand ?? null

  if (machineOrgId && !machineData) {
    return null
  }

  return (
    <Routes>
      <Route
        path="select"
        element={
          <>
            <VendorsSelector
              shippingAddress={shippingAddress}
              nearbyReferencePoint={nearbyReferencePoint}
              locationId={locationId}
              machineBrand={machineBrand}
              selectedIds={dealerLocationIds}
              onDealerLocationsChanged={onDealerLocationsChanged}
              onLocationChanged={onLocationChanged}
              vendorSelectionType={vendorSelectionType}
              excludeVendorIds={excludeVendorIds}
              broadcast={broadcast}
              onBroadcastChanged={onBroadcastChanged}
              orgMachineId={machineOrgId}
              disabledContactIds={disabledContactIds}
              tutorial={tutorial}
              singleSelection={singleSelection}
            />

            <StickyBottomBar>
              {reset && <StartOverButton reset={reset} />}

              <div className="flex-grow" />

              {onBackClicked && <Action.S onClick={onBackClicked}>Previous</Action.S>}

              <Action.P
                color="blue"
                onClick={() =>
                  broadcast === true && dealerLocationIds.length === 0
                    ? onSubmit()
                    : navigate('confirm')
                }
                disabled={
                  broadcast === undefined
                    ? dealerLocationIds.length === 0
                    : broadcast === false && dealerLocationIds.length === 0
                }
              >
                Next
              </Action.P>
            </StickyBottomBar>
          </>
        }
      />
      <Route
        path="confirm"
        element={
          <ContactAndDeliveryInformation
            shippingAddress={shippingAddress}
            dealerLocationIds={dealerLocationIds}
            locationId={locationId}
            nearbyReferencePoint={nearbyReferencePoint}
            vendors={vendors}
            reset={reset}
            locationSelectorEnabled={locationSelectorEnabled}
            orgMachineId={machineOrgId}
            onBackClicked={() => navigate('select')}
            onSubmit={(values) => {
              onContactAndDeliveryChanged(values)
              onSubmit()
            }}
            disabledContactIds={disabledContactIds}
            tutorial={tutorial}
          />
        }
      />
    </Routes>
  )
}

export default MyVendors
