import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import StartOverButton from './StartOverButton'
import StickyBottomBar from './StickyBottomBar'
import { PartialRequestUpdate, VendorSelectionType } from './types'

const SourcingButtonContent = ({ title, text }) => (
  <span className="block px-3 py-2 text-center">
    <span className="flex flex-col items-center justify-center space-y-2">
      <span className="font-medium text-lg block leading-6">{title} </span>
      <span className="block text-base leading-5">{text}</span>
    </span>
  </span>
)

const TypeButton = ({
  selected,
  title,
  text,
  onClick,
  disabled,
  disabledTooltip,
  isNew,
}: {
  selected?: boolean
  title: React.ReactNode
  text: React.ReactNode
  onClick: () => void
  disabled?: boolean
  disabledTooltip?: string
  isNew?: boolean
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={twMerge(
      'block h-32 transition duration-150 shadow-base rounded-xl relative',
      selected === true
        ? ' border-2 border-blue-600'
        : 'border border-gray-300 transition duration-200 hover:border-gray-400',
      selected === false && 'text-gray-500 ',
      disabled && 'bg-gray-100 cursor-not-allowed hover:border-gray-300',
      !selected && !disabled && 'hover:text-gray-900'
    )}
  >
    {isNew && (
      <span className="absolute -top-2 -right-3 bg-red-500 text-white font-medium text-xs rounded-full px-2 py-1">
        New
      </span>
    )}
    {disabled && disabledTooltip ? (
      <SimpleTooltip text={disabledTooltip} placement="bottom">
        <SourcingButtonContent title={title} text={text} />
      </SimpleTooltip>
    ) : (
      <SourcingButtonContent title={title} text={text} />
    )}
  </button>
)

const Section = ({ children, className = '' }) => (
  <div className={classNames('max-w-[39rem] mx-auto', className)}>{children}</div>
)

const OrderType = ({
  reset,
  request,
  updateRequest,
  onBackClicked,
  vendorSelectionType,
}: {
  reset?: () => void
  onBackClicked: () => void
  vendorSelectionType: VendorSelectionType
} & PartialRequestUpdate) => {
  const navigate = useNavigate()

  const changeType = (quickOrder: boolean) =>
    updateRequest({
      quickOrder,
    })

  const onNext = () => {
    updateRequest({
      vendors: request.quickOrder ? [] : request.vendors,
      dealerLocationIds: request.quickOrder ? [] : request.dealerLocationIds,
      broadcastToNetwork: !request.quickOrder && vendorSelectionType === 'disabled',
    })

    navigate(vendorSelectionType === 'disabled' ? '../review' : '../vendors')
  }

  return (
    <>
      <Box className="shadow-base rounded-xl px-6 py-8">
        <div className="space-y-8">
          <Section className="space-y-6">
            <h4 className="text-xl font-medium text-gray-900">
              How would you like to get your parts?
            </h4>

            <div className="grid grid-cols-2 gap-4">
              <TypeButton
                title="Get Quotes"
                text="Best for comparing prices and availability"
                selected={request.quickOrder === false}
                onClick={() => changeType(false)}
              />

              <TypeButton
                isNew
                title="Order Now"
                text="Best for urgent parts or when cost is not a concern"
                selected={request.quickOrder === true}
                onClick={() => changeType(true)}
                disabled={vendorSelectionType === 'disabled' || !!request.brokerRfqId}
                disabledTooltip={
                  request.brokerRfqId
                    ? 'Disabled for request brokering'
                    : 'Your plan does not support adding your own vendors'
                }
              />
            </div>
          </Section>
        </div>
      </Box>

      <StickyBottomBar>
        {reset && <StartOverButton reset={reset} />}

        <div className="flex-grow" />

        {onBackClicked && <Action.S onClick={onBackClicked}>Previous</Action.S>}

        <Action.P color="blue" onClick={() => onNext()} disabled={request.quickOrder === undefined}>
          Next
        </Action.P>
      </StickyBottomBar>
    </>
  )
}

export default OrderType
