import { useNavigate } from 'react-router-dom'
import { useOrderContext } from '../context'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Phone from '@/gf/modules/Phone'

const ContactVendorCard = ({ replaceOnNavigate = false }: { replaceOnNavigate?: boolean }) => {
  const { storeOrder } = useOrderContext()
  const navigate = useNavigate()

  const vendorLink = storeOrder.order.requestForQuote?.vendorLinks[0] ?? null
  const phoneNumber =
    storeOrder.store.phoneNumber ??
    vendorLink?.vendorContacts
      .map(({ phoneNumber }) => phoneNumber)
      .filter((phone) => !!phone)[0] ??
    null
  const address = storeOrder.store.address ?? vendorLink?.vendor.address

  return (
    <Box className="p-4 space-y-1 bg-white border rounded-lg text-left w-full">
      <div className="space-y-1 flex gap-x-2">
        {storeOrder.store.logo?.url && (
          <img
            src={storeOrder.store.logo?.url}
            alt=""
            className="block border rounded-full w-12 h-12 shadow-base object-cover"
          />
        )}

        <div className="space-y-1">
          <h5 className="text-base font-medium">{storeOrder.store.name}</h5>

          {address && (
            <p className="text-sm">
              <>
                {address.city}, {address.state}
              </>
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center">
        {phoneNumber && (
          <p className="text-gray-700 text-sm font-medium">{Phone.format(phoneNumber)}</p>
        )}

        <Action.S
          onClick={() =>
            navigate(`/orders/${storeOrder.id}/messages/store/${storeOrder.store.id}`, {
              replace: replaceOnNavigate,
            })
          }
        >
          Message
        </Action.S>
      </div>
    </Box>
  )
}

export default ContactVendorCard
