import useSession from '@/buyers/hooks/useSession'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { CreateRequestState } from './types'
import useLocalStorage from './useLocalStorage'

const emptyState: CreateRequestState = {
  billingCompanyId: undefined,
  workOrderNumber: undefined,
  machineOrgId: undefined,
  machineInvolved: true,
  urgency: undefined,
  parts: [
    {
      partNumber: '',
      description: '',
      quantity: 1,
      externalId: null,
      taskNumber: null,
      suggestion: null,
      pictures: [],
    },
  ],
  comments: '',

  dealerLocationIds: [],
  vendors: [],

  locationId: undefined,
  shippingAddress: undefined,
  nearbyReferencePoint: undefined,

  quickOrder: undefined,
  broadcastToNetwork: false,

  tutorial: false,
}

const usePersistedState = (
  storageKey: 'new-request-data-2' | 'aprove-request-next-state-2',
  initialState: Partial<CreateRequestState> = {}
) => {
  const { user } = useSession()
  const userUniqueKey = useMemo(() => `${storageKey}-${user.id}`, [user.id, storageKey])

  const {
    value: state,
    setValue: setState,
    reset,
  } = useLocalStorage<CreateRequestState>(
    userUniqueKey,
    { ...emptyState, ...initialState },
    (rawValue) => {
      const urgency = rawValue.urgency
        ? {
            ...rawValue.urgency,
            neededByDate: rawValue.urgency.neededByDate
              ? DateTime.fromISO(rawValue.urgency.neededByDate as unknown as string)
              : rawValue.urgency.neededByDate,
          }
        : undefined

      return { ...rawValue, urgency }
    }
  )

  const update = (changes: Partial<CreateRequestState>) => {
    setState((prev: CreateRequestState) => ({ ...prev, ...changes }))
  }

  return { values: state, update, reset, emptyState }
}

export default usePersistedState
