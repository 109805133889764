import { FormEvent, useState } from 'react'

import {
  Location,
  LocationsSelectDocument,
  LocationsSelectQuery,
  LocationsSelectQueryVariables,
} from '@/buyers/_gen/gql'
import AutocompleteSingleSelectInputNext from '@/buyers/components/AutocompleteSingleSelectInputNext'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import ModalForm from '@/gf/components/ModalForm'
import Field from '@/gf/components/next/forms/Field'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import ShippingLocationM from '@/gf/modules/ShippingLocation'

type Props = {
  show: boolean
  onClose: () => void
  update: (data: { location: Location }) => void
}

const EditShippingAddress = ({ show, onClose, update }: Props) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [location, setLocation] = useState<Location>()

  const editShippingLocation = async (event: FormEvent<HTMLFormElement>) => {
    if (!location) return
    event.preventDefault()
    msgr.clear()
    spinner.on()

    update({ location })
    msgr.add('Shipping location updated.', 'positive')
    close()
    spinner.off()
  }

  return (
    <ModalForm
      open={show}
      onClose={onClose}
      title="Select Location"
      submitButtonDisabled={!location}
      submitButtonShowSpinner={spinnerLive}
      onSubmit={editShippingLocation}
    >
      <div className="space-y-4">
        <Field>
          <AutocompleteSingleSelectInputNext<
            LocationsSelectQuery,
            LocationsSelectQueryVariables,
            NonNullable<LocationsSelectQuery['org']>['locations'][number]
          >
            gqlClient={client}
            query={LocationsSelectDocument}
            queryVars={(val) => ({ orgId, value: val })}
            onChange={(shippingLocation) => setLocation((shippingLocation as Location) ?? null)}
            transformQueryToData={(queryData) => queryData.org?.locations || []}
            transformDatumToOption={(shippingLocation) => ({
              value: shippingLocation?.id ?? '',
              label: shippingLocation ? ShippingLocationM.label(shippingLocation) : '',
            })}
          />
        </Field>
      </div>
    </ModalForm>
  )
}

export default EditShippingAddress
