import Modal from '@/gf/components/ModalNext'
import { ModalSize } from '@/types'
import { ListManualsDocument, useUploadManualMutation } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import UploadButton from '@/gf/components/UploadButton'
import { UploadIcon } from '@heroicons/react/outline'
import CloseModalButton from '@/gf/components/CloseModalButton'
import { useEffect, useState } from 'react'
import useMsgs from '@/gf/hooks/useMsgs'
import Action from '@/gf/components/Action'
import Spinner from '@/gf/components/Spinner'

const messages = {
  ready: {
    title: 'Upload Parts Manual',
    subtitle: 'Select and upload your parts manual document to begin.',
  },
  in_progres: {
    title: 'Uploading Document',
    subtitle: 'Your parts manual is being uploaded. Please wait.',
  },
  complete: {
    title: 'Almost There!',
    subtitle:
      "We're now processing your parts manual. This can take some time, so you can keep an eye on the upload progress tab for more information.",
  },
}

const UploadPartsManual = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [stage, setStage] = useState<'ready' | 'in_progres' | 'complete'>('ready')
  const [_, msgs] = useMsgs()
  const client = useGqlClient()
  const [uploadPartsManualMutation] = useUploadManualMutation({
    client,
    refetchQueries: [ListManualsDocument],
  })

  const onUploadStarted = () => setStage('in_progres')

  const onUploadComplete = async (files: { name: string; url: string }[]) => {
    try {
      await uploadPartsManualMutation({ variables: { url: files[0].url, orgMachineId: null } })

      setStage('complete')
    } catch (err) {
      console.error(err)
      msgs.addUnknownError()
      setStage('ready')
    }
  }

  const onError = () => {
    msgs.addUnknownError()

    setStage('ready')
  }

  useEffect(() => {
    setStage('ready')
  }, [open])

  return (
    <Modal size={ModalSize.SM} open={open} onClose={onClose}>
      <div className="relative p-6">
        <CloseModalButton className="absolute top-3 right-3" onClick={onClose} />
        <hgroup className="space-y-1">
          <h2 className="text-2xl font-medium">{messages[stage].title}</h2>
          <p className="text-lg">{messages[stage].subtitle}</p>
        </hgroup>
        <div className="pt-4 flex justify-center">
          {stage === 'ready' && (
            <UploadButton
              allowedFileTypes={['application/pdf']}
              onUploadComplete={onUploadComplete}
              onError={onError}
              onUploadStarted={onUploadStarted}
            >
              <Action.P color="blue">
                <UploadIcon className="inline-block h-6 w-6" />
                Select Parts Manual
              </Action.P>
            </UploadButton>
          )}

          {stage === 'in_progres' && <Spinner size="md" color="blue" />}

          {stage === 'complete' && (
            <Action.P color="blue" onClick={onClose}>
              Close
            </Action.P>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UploadPartsManual
