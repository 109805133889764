import RocketLayout from '@/gf/components/RocketLayout'
import { ActivitiesIcon, MessagesIcon, TasksIcon } from '@/gf/components/RocketLayout/HeaderActions'
import type { HeaderAction, NavItemGroup } from '@/gf/components/RocketLayout/types'
import type { Breadcrumbs } from '@/types'
import {
  BookOpenIcon,
  ChartSquareBarIcon,
  ClipboardCheckIcon,
  ClipboardListIcon,
  CogIcon,
  CurrencyDollarIcon,
  LocationMarkerIcon,
  PaperAirplaneIcon,
  TemplateIcon,
  TruckIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { ReactNode, RefObject, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useFrame from '../hooks/useFrame'
import useSession from '../hooks/useSession'
import ActivitiesPanel from './Frame/ActivitiesPanel'
import DevMenu from './Frame/DevMenu'
import MachineIcon from './Frame/Icons/MachineIcon'
import OrgDetails from './Frame/OrgDetails'
import RequiredInfo from './Frame/RequiredInfo'
import SearchResults from './Frame/Search/SearchResults'
import TasksPanel from './Frame/TasksPanel'
import useSidePanel, { SidebarPanelTypes } from './Frame/useSidePanel'

const Frame = ({
  children,
  contentClassName,
  breadcrumbs,
  contentRef,
  topBanner,
}: {
  children: React.ReactNode
  contentClassName?: string
  breadcrumbs?: Breadcrumbs
  contentRef?: RefObject<HTMLElement>
  topBanner?: ReactNode
}) => {
  const navigate = useNavigate()
  const { organization, user, featureFlags } = useSession()
  const frameData = useFrame()
  const { current: currentPanel, close: closePanel, open: openPanel } = useSidePanel()

  const headerActions = useMemo<HeaderAction[]>(
    () => [
      {
        label: 'Inbox',
        onClick: () => navigate('/inbox'),
        icon: <MessagesIcon />,
        count: frameData?.unreadConversations,
      },
      {
        label: 'Activities',
        onClick: () => openPanel(SidebarPanelTypes.ACTIVITIES),
        icon: <ActivitiesIcon />,
        count: frameData?.todayActivitiesCount,
      },
      {
        label: 'Tasks',
        onClick: () => openPanel(SidebarPanelTypes.TASKS),
        icon: <TasksIcon />,
        count: frameData?.todos.length,
      },
    ],
    [frameData]
  )

  const navItems = useMemo<NavItemGroup[]>(
    () => [
      {
        name: 'main',
        items: [
          {
            children: (
              <>
                <TemplateIcon className="h-5 w-5 inline-block " /> Dashboard
              </>
            ),
            href: '/',
          },
          {
            children: (
              <>
                <PaperAirplaneIcon className="h-5 w-5 inline-block rotate-90" /> Requests
              </>
            ),
            href: '/rfqs',
          },
          {
            children: (
              <>
                <ClipboardCheckIcon className="h-5 w-5 inline-block " /> Orders
              </>
            ),
            href: '/orders',
          },

          ...(organization.invoicesEnabled
            ? [
                {
                  children: (
                    <>
                      <CurrencyDollarIcon className="h-5 w-5 inline-block " />
                      Invoices
                    </>
                  ),
                  href: '/invoices',
                },
              ]
            : []),

          {
            children: (
              <>
                <ChartSquareBarIcon className="h-5 w-5 inline-block " />
                Reports
              </>
            ),
            href: '/reporting',
          },
        ],
      },
      {
        name: 'management',
        items: [
          {
            children: (
              <>
                <MachineIcon className="inline-block " />
                Fleet
              </>
            ),
            href: '/fleet',
          },
          {
            children: (
              <>
                <LocationMarkerIcon className="h-5 w-5 inline-block " />
                Locations
              </>
            ),
            href: '/locations',
          },
          {
            children: (
              <>
                <TruckIcon className="h-5 w-5 inline-block " />
                Vendors
              </>
            ),
            href: '/vendors',
          },
          {
            children: (
              <>
                <ClipboardListIcon className="h-5 w-5 inline-block " />
                Inventory
              </>
            ),
            href: '/inventory',
          },

          ...(user.can.manageUsers
            ? [
                {
                  children: (
                    <>
                      <UserGroupIcon className="h-5 w-5 inline-block " />
                      Team
                    </>
                  ),
                  href: '/settings/users',
                },
              ]
            : []),
        ],
      },
      {
        name: 'settings',
        items: [
          ...(featureFlags.partsManualsUpload
            ? [
                {
                  children: (
                    <>
                      <BookOpenIcon className="h-5 w-5 inline-block" />
                      Knowledge Center
                    </>
                  ),
                  href: '/knowledge-center',
                },
              ]
            : []),
          {
            children: (
              <>
                <CogIcon className="h-5 w-5 inline-block" />
                Settings
              </>
            ),
            href: '/settings',
          },
        ],
      },
    ],
    [organization, user, featureFlags]
  )

  return (
    <RocketLayout
      orgDetails={<OrgDetails isProPlan={frameData?.isProPlan} />}
      user={{ name: user.displayName }}
      subscription={{
        productName: 'Parts Hub',
        upgradePlanHref: '/settings/billing/details?upgrade=1',
        inTrialPeriod: organization.buyerDashboardAccess === true,
        trialExpirationDate: organization.buyerDashboardAccessExpiresAt ?? undefined,
      }}
      breadcrumbs={breadcrumbs}
      headerActions={headerActions}
      navItems={navItems}
      navAction={{
        href: `/rfqs/create?source.path=${encodeURIComponent(
          document.location.pathname
        )}&source.button=create-request-sidebar`,
        label: 'Order Parts',
      }}
      renderSearch={({ onClose, searchTerm }) => (
        <SearchResults onItemSelected={onClose} searchTerm={searchTerm} />
      )}
      contentClassName={contentClassName}
      className=""
      header={<DevMenu />}
      topBanner={topBanner}
      contentRef={contentRef}
    >
      <TasksPanel open={currentPanel === SidebarPanelTypes.TASKS} onClose={closePanel} />
      <ActivitiesPanel open={currentPanel === SidebarPanelTypes.ACTIVITIES} onClose={closePanel} />
      <RequiredInfo />
      {children}
    </RocketLayout>
  )
}

export default Frame
