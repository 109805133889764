import { useNavigate } from 'react-router-dom'
import { useOrderContext } from '../context'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { CheckIcon } from '@heroicons/react/solid'
import ContactVendorCard from './ContactVendorCard'

const QuickOrderModal = () => {
  const { storeOrder } = useOrderContext()
  const navigate = useNavigate()
  const close = () => navigate(`/orders/${storeOrder.id}`)

  return (
    <Modal onClose={close}>
      <div className="relative p-6 space-y-6">
        <CloseModalButton onClick={close} className="absolute top-3 right-3" />

        <hgroup className="space-y-2">
          <h3 className="text-2xl font-medium flex items-center gap-x-4">
            <div className="bg-green-500 rounded-full flex items-center justify-center h-8 w-8 border-green-200 border-4">
              <CheckIcon className="w-5 h-5 text-white" />
            </div>
            Purchase Order Sent
          </h3>
          <p className="text-lg text-gray-600">
            You will be notified as soon as your vendor sends an invoice and marks your parts ready
            for pickup or shipped.
          </p>
        </hgroup>

        <section className="space-y-2">
          <h4 className="text-lg font-medium">What happens next</h4>
          <p className="text-gray-600 text-base">
            You'll be notified on Gearflow when your vendor sends an invoice and marks your parts
            ready for pickup or shipped.
          </p>
        </section>

        <section className="space-y-2">
          <h4 className="text-lg font-medium">Want to check on price and availability?</h4>
          <p className="text-gray-600 text-base">
            To check the price and availability of ordered parts, you can message or call your
            vendor.
          </p>
        </section>

        <ContactVendorCard replaceOnNavigate />

        <div className="flex justify-end">
          <Action.P onClick={() => navigate('../', { replace: true })} color="blue" size="lg">
            View Order Detail
          </Action.P>
        </div>
      </div>
    </Modal>
  )
}

export default QuickOrderModal
